<template>
    <!--components/ModalDialog.vue-->
    <div class="modal modal-md" tabindex="-1" aria-labelledby="Modal Dialog" aria-hidden="true" ref="element_ref">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content collaboration-modal">
                <h5 class="modal-title" v-if="props.title != ''">{{ props.title }}</h5>
                    <div class="text-end modal-close">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" v-if="props.show_close"></button>
                    </div>

                <div class="modal-body">
                    <p style="text-align: center">{{ message }}</p>
                    <div style="text-align: center">
                        <button class="btn btn-primary ok-btn" @click="$emit('close')">OK</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    const props = defineProps({
        title: {
            type: String,
            required: false,
            default: ""
        },
        message: {
            type: String,
            required: true
        },
        show_close: {
            type: Boolean,
            required: false,
            default: false
        },
        opacity: {
            type: String,
            required: false,
            default: '0%'
        },
        width: {
            type: String,
            required: false,
            default: '50%'
        }
    });
    let emit = defineEmits("close");

    let element_ref = ref(null);
    onMounted(() => {
        // Here, we have to use a setTimeout because element_ref.value may not be readily available yet
        setTimeout(() => {
            hideTooltips(document.getElementsByTagName('body')[0]);
            var myModal = new bootstrap.Modal(element_ref.value, { backdrop: false });
            myModal.show();

            element_ref.value.addEventListener('hidden.bs.modal', function (event) {
                emit('close');
            });

        }, 1);
    })
</script>
